import { createAxiosByinterceptors } from "@/api/request";
import url from "@/constant/url";
const vueAppEnv = process.env.VUE_APP_ENV;

const request = createAxiosByinterceptors({
  baseURL: (url as any)[vueAppEnv as any].home,
});

//同步考勤
export const getAttendanceList = (data: any): Promise<any> =>
  request.post("/api/attendance/getAttendanceList", data);

//考勤列表
export const queryAttendanceList = (params: any): Promise<any> =>
  request.get("/api/attendance/queryAttendanceList", { params });

//考勤列表
export const conditionalQueryAttendanceList = (params: any): Promise<any> =>
  request.get("/api/attendance/conditionalQueryAttendanceList", { params });

//考勤导出
export const downloadAttendanceExcel = (): Promise<any> =>
  request.get("/api/attendance/downloadAttendanceExcel");

//考勤导入
export const uploadAttendanceExcel = (): Promise<any> =>
  request.post("/api/attendance/uploadAttendanceExcel");

//考勤确认
export const saveAttendanceList = (): Promise<any> =>
  request.get("/api/attendance/saveAttendanceList");

//获取员工Id
export const getAllMemberIds = (params: any): Promise<any> =>
  request.get("/api/attendance/getAllMemberIds", { params });

//获取历史考勤数据
export const getHistoryAttendanceList = (params: any): Promise<any> =>
  request.get("/api/attendance/getHistoryAttendanceList", { params });

//考勤修改
export const updateAttendance = (data: any): Promise<any> =>
  request.post("/api/attendance/updateAttendance", data);
