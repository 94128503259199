import { createAxiosByinterceptors } from "@/api/request";
import url from "@/constant/url";
const vueAppEnv = process.env.VUE_APP_ENV;

const request = createAxiosByinterceptors({
  baseURL: (url as any)[vueAppEnv as any].home,
});

//获取部门
export const departmentDetails = (id: any): Promise<any> =>
  request.get("/api/department/" + id);

//新增部门
export const departmentAdd = (data: any): Promise<any> =>
  request.post("/api/department", data);

//修改部门
export const departmentUpdate = (id: string, data: any): Promise<any> =>
  request.put("/api/department/" + id, data);

//部门列表
export const departmentList = (params: any): Promise<any> =>
  request.get("/api/department", { params });

// 部门删除
export const departmentDelete = (id: any): Promise<any> =>
  request.delete("/api/department/" + id);

//同步部门
export const SynchronousDepartment = (params: any): Promise<any> =>
  request.get("/api/department/SynchronousDepartment", { params });
