






























































































































































































































































import Vue from "vue";
import { updateAttendance } from "@/api/checkingIn/index";
export default Vue.extend({
  props: ["isEditFlag", "baseEditInfo", "departmentList"],
  data() {
    return {
      editFormInfo: {
        id: null,
        workNumber: null, //工号
        memberId: null, //部门

        shouldAttendanceDays: null, //应出勤天数
        attendanceDays: null, //出勤天数
        leaveEarlyTimes: null, //早退次数
        lateTimes: null, //迟到次数
        onWorkLackCardTimes: null, //上班缺卡次数
        offWorkLackCardTimes: null, //下班缺卡次数
        absenteeismDays: null, //旷工天数
        leavePersonal: null, //事假(天)
        leaveSick: null, //病假
        simpleListCount: null, //日报次数
        maternityLeave: null, //产假
        otherLeave: null, //其他假期
        // onboardTime: null, //入职时间
        // resignTime: null, //离职时间
      },
      formatType: "yyyy-MM-dd HH:mm:ss",
      editFormInfoRules: {},
    };
  },
  mounted() {
    if (this.baseEditInfo.id) {
      this.editFormInfo = this.baseEditInfo;
    }
  },

  methods: {
    normalizerApp(node: any) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children,
      };
    },
    saveFn: async function () {
      let flag = true;
      (this.$refs.editFormInfoForm as any).validate((valid: boolean) => {
        if (!valid) {
          flag = false;
        }
      });
      if (!flag) {
        return;
      }

      let obj = this.$deepCopy(this.editFormInfo);
      updateAttendance(obj).then((res) => {
        if (res.status == "SUCCESS") {
          this.$message.success("保存成功");
          this.closeFn(true);
        } else {
          this.$message.warning("保存失败");
        }
      });
    },
    closeFn(flag = false) {
      this.$emit("closeFn", flag);
    },
  },
});
