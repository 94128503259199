























































































































































































































































































import Vue from "vue";
import {
  getAttendanceList,
  conditionalQueryAttendanceList,
  downloadAttendanceExcel,
  saveAttendanceList,
  getAllMemberIds,
} from "@/api/checkingIn/index";
import { memberList } from "@/api/salary/member";
import miniUploadFileForm from "@/components/common/miniUploadFileForm.vue";
import { reportList } from "@/api/system/dict";
import { departmentList } from "@/api/user/department";
import editForm from "./editForm.vue";
export default Vue.extend({
  components: { miniUploadFileForm, editForm },
  data() {
    return {
      searchObj: {
        name: "",
        workNumber: "",
        isZero: "false",
      },
      timeValue: "", //时间
      dailyPaper: 0,
      dailyPaperUse: 0,
      formatType: "yyyy年M月",
      pageObj: {
        pageSize: 10,
        pageNum: 1,
      },
      pageSizesList: [10, 15, 20, 50, 100],
      tableTotal: 0,
      tableData: [],

      editFormVisible: false,
      isEditFlag: false,
      isEditType: "edit",
      baseEditInfo: {},
      direction: "rtl",
      pickerOptions: {
        disabledDate(time: Date) {
          return time.getTime() > Date.now();
        },
      },
      userList: [],

      asyVisible: false,
      userIds: [],
      idActive: 0,
      idCount: 0, //考勤同步计数
      failList: [], //失败列表

      //字典数据
      reportList: [],
      departmentList: [],

      isExitFlag: false,
    };
  },
  watch: {},
  computed: {
    dailyPaperFormmter() {
      return function (val) {
        if (val === 1) {
          return "是";
        } else {
          return "否";
        }
      };
    },
  },
  beforeDestroy() {
    this.isExitFlag = true;
  },
  mounted() {
    departmentList({}).then((res) => {
      this.departmentList = res.content || [];
    });
    this.reportList = reportList as any;
    // memberList().then((res: any) => {
    //   this.userList = res.content?.records || [];
    // });
    this.getList();
  },
  methods: {
    //common
    resetFn() {
      this.searchObj = this.$options.data().searchObj;
      this.searchFn();
    },
    searchFn() {
      this.pageObj = this.$options.data().pageObj;
      this.getList();
    },
    pageNumChange(val: any) {
      this.pageObj.pageNum = val;
      this.getList();
    },
    pageSizeChange(val: any) {
      this.pageObj.pageNum = 1;
      this.pageObj.pageSize = val;
      this.getList();
    },
    getList() {
      this.getListData();
    },
    listEditFn(row: any, type: string) {
      this.editFormVisible = true;
      this.isEditFlag = true;
      this.isEditType = type;
      this.baseEditInfo = this.$deepCopy(row);
    },
    listDeleteFn() {
      this.$confirm("是否删除本条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$message({
          type: "success",
          message: "删除成功",
        });
        this.searchFn();
      });
    },
    closeFn(flag = false) {
      this.editFormVisible = false;
      this.isEditType = "";
      if (flag) {
        this.getList();
      }
    },
    //业务
    async getListData() {
      let obj = Object.assign({}, this.searchObj);
      await conditionalQueryAttendanceList(obj).then((res: any) => {
        this.tableData = res.content || [];
        this.tableTotal = res.content?.total || 0;
      });

      this.$nextTick(() => {
        this.$refs.tableDiv.doLayout();
      });
    },
    addFn() {
      console.log("获取新增");
    },
    deleteFn() {
      console.log("删除");
    },
    exportFn() {
      downloadAttendanceExcel().then((res) => {
        this.saveAs(res);
      });
    },
    fileUploadSuccess() {
      this.getList();
    },
    saveAs(data) {
      const blob = new Blob([data], { type: "application/vnd.ms-excel" }); //创建一个新的 Blob 对象
      const URL = window.URL || window.webkitURL;
      const url = URL.createObjectURL(blob); //  把一个blob对象转化为一个Blob URL，创建下载链接
      const downloadLink = document.createElement("a"); // 生成一个a标签
      downloadLink.href = url;
      downloadLink.download = "考勤数据.xlsx"; // // dowload属性指定下载后文件名
      document.body.appendChild(downloadLink); //将a标签添加到body中
      downloadLink.click(); // 点击下载
      document.body.removeChild(downloadLink); // 下载完成后移除元素
      window.URL.revokeObjectURL(url); // 释放掉blob对象
    },
    importFn() {
      console.log("批量导入");
    },
    async synFn() {
      if (!this.timeValue) {
        this.$message.warning("请输入同步日期");
        return;
      }
      await getAllMemberIds({ dateTag: this.timeValue }).then((res) => {
        this.userIds = res.content || [];
      });

      if (this.userIds.length == 0) {
        this.$message.warning("暂无需要同步人员");
        return;
      }
      this.asyVisible = true;
      this.dailyPaperUse = this.dailyPaper;

      if (this.idActive > 0 && this.idActive < this.userIds.length - 1) {
        this.$confirm("同步未结束，是否重新开始同步?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.idActive = 0;
            this.idCount = 0;
            this.failList = [];
            this.$message.success("同步开始");
            this.getAttenFn();
          })
          .catch(() => {
            //this.getAttenFn();
          });
      } else {
        this.$message.success("同步开始");
        this.idActive = 0;
        this.idCount = 0;
        this.failList = [];
        this.getAttenFn();
      }
    },
    getAttenFn() {
      if (this.isExitFlag) {
        return;
      }
      let obj = {
        time: this.timeValue,
        mId: this.userIds[this.idActive],
        dailyPaper: this.dailyPaperUse,
      };

      getAttendanceList(obj)
        .then((res) => {
          if (res.status == "SUCCESS") {
            if (this.idActive == this.userIds.length - 1) {
              this.$message.success("考勤同步完成");
              this.idCount = 0;
              this.getListData();
            } else {
              this.idActive++;
              this.idCount = 0;
              this.getAttenFn();
            }
          } else {
            if (this.idCount == 0) {
              this.idCount++;
              this.getAttenFn();
            } else {
              this.failList.push(this.userIds[this.idActive]);
              if (this.idActive == this.userIds.length - 1) {
                this.$message.success("考勤同步完成");
                this.getListData();
                this.idCount = 0;
              } else {
                this.idActive++;
                this.idCount = 0;
                this.getAttenFn();
              }
            }
          }
        })
        .catch(() => {
          this.$message.warning("考勤同步异常,请稍后尝试!");
          this.getListData();
        });
    },
    subFn() {
      saveAttendanceList().then(() => {
        this.$message.success("考勤已确认");
      });
    },
    getDepartment(depart: any, flag = true) {
      let department_ = "";
      if (flag) {
        department_ = this.showDepartment(depart);
        if (department_.length > 0) {
          department_ = department_.substr(0, department_.length - 1);
        }
      } else {
        department_ = depart?.name || "";
      }

      return department_;
    },
    showDepartment(depart: any) {
      let department_ = "";
      if (depart && Object.keys(depart).length > 0) {
        department_ = depart.name + "/" + department_;
        department_ = this.showDepartment(depart.parent) + department_;
      }

      return department_;
    },
  },
});
